$(document).ready(function(){

	var baseUrl = xSell['hotelSiteUrl'];
	if (!baseUrl) {
		baseUrl = "https://myhotels.aircanada.com";
	}
	var roomStayCadidates = "";
	var numberOfRooms = xSell['numberOfRooms'];
	for(var i = 0; i < parseInt(numberOfRooms); i++){
		roomStayCadidates = roomStayCadidates + xSell['r' + i] + ";"
	}
	if(roomStayCadidates.length != 0){
		roomStayCadidates = roomStayCadidates.substring(0,roomStayCadidates.length-1);
	}
	$.ajax({
		url: baseUrl + '/view/SyndicatedHotelCrossSellComponentController/renderComponent',
		async: true,
		data:{
		    'checkInDate' : xSell['outboundDate'],
            'checkOutDate' : xSell['inboundDate'],
            'numberOfAdults' : xSell['nrOfAdults'],
            'numberOfChildren' :xSell['nrOfChildren'],
            'airportArrival': xSell['airportArrival'],
            'currency' : xSell['currency'],
            'numberOfRooms': xSell['numberOfRooms'],
            'childAges': xSell['childAges'],
            'locale' : xSell['locale']
		},
		dataType : "html"
	}).done(function (data)
	{
		$('#' + xSell['widgetID']).html(data);
	});
});
